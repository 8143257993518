@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  font-family: "Poppins", sans-serif;
}

html {
  scroll-padding-top: 100px;

  @media screen and (max-width: 768px) {
    scroll-padding-top: 70px;
  }
}

body {
  margin: 0px !important;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  background-color: #090917;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 80px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #222a35;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #575c66;
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #626970;
}

::-webkit-backdrop-filter {
  blur: 3px saturate(106%);
}

#nav-icon1 {
  width: 30px;
  height: 22.5px;
  position: relative;
  margin: 50px auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

#nav-icon1 span {
  display: block;
  position: absolute;
  height: 4.5px;
  width: 100%;
  background: #854ce6;
  border-radius: 4.5px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

#nav-icon1 span:nth-child(1) {
  top: 0px;
}

#nav-icon1 span:nth-child(2) {
  top: 9px;
}

#nav-icon1 span:nth-child(3) {
  top: 18px;
}

#nav-icon1.open span:nth-child(1) {
  top: 9px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

#nav-icon1.open span:nth-child(2) {
  opacity: 0;
  left: -30px;
}

#nav-icon1.open span:nth-child(3) {
  top: 9px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
